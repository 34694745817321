// QuizLoopPage.jsx
import React, { useState, useEffect } from 'react';
import QuizCard from "../../../components/QuizCard/QuizCard";
import quizService from "../../../client/quizClient";

export default function QuizLoopPage() {
    const [quiz, setQuiz] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMultipleCorrect, setIsMultipleCorrect] = useState(false);

    const fetchQuiz = async () => {
        setIsLoading(true);
        try {
            const data = await quizService.fetchQuizForLoop(); // предполагаемая функция в quizService
            setQuiz(data);
            setIsMultipleCorrect(data.answers.filter(answer => answer.isCorrect).length > 1);
            setIsLoading(false);
            setChecked(false);
            setSelectedOptions(new Set());
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchQuiz();
    }, []);

    const handleOptionChange = (event) => {
        const newSelectedOptions = new Set(selectedOptions);
        if (isMultipleCorrect) {
            if (newSelectedOptions.has(event.target.value)) {
                newSelectedOptions.delete(event.target.value);
            } else {
                newSelectedOptions.add(event.target.value);
            }
        } else {
            newSelectedOptions.clear();
            newSelectedOptions.add(event.target.value);
        }
        setSelectedOptions(newSelectedOptions);
    };

    const handleCheckClick = () => {
        setChecked(true);

        if (quiz && quiz.id) {
            const isCorrect = isMultipleCorrect
                ? Array.from(selectedOptions).every(option => isAnswerCorrect(option))
                : isAnswerCorrect([...selectedOptions][0] || '');

            quizService.submitQuizAnswer(quiz.id, isCorrect) // предполагаемая функция в quizService
                .then(response => {
                    // Обработка успешного ответа, если необходимо
                    // Например, обновление состояния или показ уведомлений
                })
                .catch(err => {
                    console.error("There was an error submitting the answer: ", err);
                    // Обработка ошибки, например, показ сообщения об ошибке
                });
        }
    };


    const handleNextClick = () => {
        fetchQuiz();
    };

    const isAnswerCorrect = (answerText) => {
        return quiz && quiz.answers.some(answer => answer.text === answerText && answer.isCorrect);
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <p>Error loading quiz: {error}</p>;

    return quiz ? (
        <QuizCard
            quiz={quiz}
            isMultipleCorrect={isMultipleCorrect}
            selectedOptions={selectedOptions}
            handleOptionChange={handleOptionChange}
            checked={checked}
            handleCheckClick={handleCheckClick}
            handleNextClick={handleNextClick}
            isAnswerCorrect={isAnswerCorrect}
        />
    ) : (
        <div>No quiz data available.</div>
    );
}
