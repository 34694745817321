
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Topbar from './Topbar/Topbar';
import Drawer from './Drawer/Drawer';
import { toggleDrawer } from "../redux/actions/drawerActions";
import { useNavigate } from 'react-router-dom'; // Добавьте импорт useNavigate


const Layout = ({ children }) => {
    const isDrawerOpen = useSelector(state => state.drawer.isOpen);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn); // Получение состояния isLoggedIn
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Используйте хук useNavigate


    const handleToggleDrawer = () => {
        dispatch(toggleDrawer());
    };

    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);


    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login"); // Перенаправляйте на страницу входа, если пользователь не залогинен
        }
    }, [isLoggedIn, navigate]);



    useEffect(() => {

        const mediaQuery = window.matchMedia("(max-width: 768px)");
        const handleMediaChange = () => {
            setIsMobile(mediaQuery.matches);
        };
        mediaQuery.addListener(handleMediaChange);
        return () => mediaQuery.removeListener(handleMediaChange);
    }, []);

    const drawerStyle = isMobile ? {
        width: '100%',
        height: 'calc(100vh - 50px)', // 50px предполагаемая высота Topbar
        top: '50px', // Смещение на высоту Topbar
        position: 'fixed',
        backgroundColor: 'white',
        borderRight: 'none',
        boxSizing: 'border-box',
        zIndex: 1000
    } : {
        width: '180px',
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        borderRight: '2px solid #e0e0e0',
        boxSizing: 'border-box'
    };

    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
            {isLoggedIn && (isMobile ? !isDrawerOpen : isDrawerOpen) && (
                <div style={drawerStyle}>
                    <Drawer />
                </div>
            )}
            <div style={{ flex: 1, overflow: 'auto' }}>
                <Topbar toggleDrawer={handleToggleDrawer} />
                <div style={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    padding: '10px', /* Padding on top, right, and left */
                    paddingBottom: '25px', /* Additional padding at the bottom */
                }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
