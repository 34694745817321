import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import quizService from "../../../client/quizClient";
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const initialAnswers = [
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
    { text: '', isCorrect: false },
];

export default function QuizAddPage() {
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([...initialAnswers]);
    const [answersNotDefinitive, setAnswersNotDefinitive] = useState(false);

    const handleCheckboxDefinitiveChange = () => {
        setAnswersNotDefinitive(!answersNotDefinitive);
    };

    const handleAnswerChange = (index, value) => {
        const newAnswers = [...answers];
        newAnswers[index].text = value;
        setAnswers(newAnswers);
    };

    const handleCheckboxChange = (index) => {
        const newAnswers = [...answers];
        newAnswers[index].isCorrect = !newAnswers[index].isCorrect;
        setAnswers(newAnswers);
    };

    const handleAddQuestion = async () => {
        try {
            const data = await quizService.addQuiz(question, answers, answersNotDefinitive);
            toast.success(`Quiz added successfully. Response: ${JSON.stringify(data)}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
            setQuestion('');
            setAnswersNotDefinitive(false);
            setAnswers(initialAnswers.map(answer => ({ ...answer, text: '', isCorrect: false })));


        } catch (error) {
            console.error('Error saving quiz:', error);

            toast.error(`Error saving quiz: ${error.message}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }
    };

    const handleAddAnswer = () => {
        const newAnswers = [...answers, { text: '', isCorrect: false }];
        setAnswers(newAnswers);
    };

    const handleClearForm = () => {
        console.log("enter clear")
        setQuestion('');
        setAnswersNotDefinitive(false);
        setAnswers(initialAnswers.map(answer => ({ ...answer, text: '', isCorrect: false })));

    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    size="small"
                    label="Question"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={7}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
            </Grid>
            {answers.map((answer, index) => (
                <Grid container item xs={12} key={index} spacing={2} alignItems="center">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={answer.isCorrect}
                                    onChange={() => handleCheckboxChange(index)}
                                />
                            }
                            label=""
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            size="small"
                            label={`Answer ${index + 1}`}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={2}
                            value={answer.text}
                            onChange={(e) => handleAnswerChange(index, e.target.value)}
                        />
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={answersNotDefinitive}
                                    onChange={handleCheckboxDefinitiveChange}
                                />
                            }
                            label="answersNotDefinitive"
                        />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: isMobile ? 'right' : 'inherit' }}>
                        <Stack direction="row" spacing={3} justifyContent={'flex-end'}>
                            <Button variant="outlined" onClick={handleAddAnswer} startIcon={<PlaylistAddOutlinedIcon />}>
                                Answer
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={handleClearForm}
                                startIcon={<ClearOutlinedIcon />}

                                style={{ marginRight: isMobile ? '0px' : '30px' }}>
                                Clear
                            </Button>
                            <Button variant="outlined" onClick={handleAddQuestion} startIcon={<LibraryAddOutlinedIcon />}>
                                Quiz
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <ToastContainer />
        </Grid>
    );
}
