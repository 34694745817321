import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from "./layout/Layout";

import LoginPage from "./pages/auth/LoginPage";
import QuizLoopPage from "./pages/quiz/QuizLoopPage/QuizLoopPage";
import QuizLearnPage from "./pages/quiz/QuizLearnPage/QuizLearnPage";
import QuizStatPage from "./pages/quiz/QuizStatPage/QuizStatPage";
import QuizSetPage from "./pages/quiz/QuizSetPage/QuizSetPage";
import QuizListPage from "./pages/quiz/QuizListPage/QuizListPage";
import QuizAddPage from "./pages/quiz/QuizAddPage/QuizAddPage";

function App() {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/loop" element={<QuizLoopPage />} />
                    <Route path="/learn" element={<QuizLearnPage />} />
                    <Route path="/add" element={<QuizAddPage />} />
                    <Route path="/stat" element={<QuizStatPage />} />
                    <Route path="/" element={<QuizStatPage />} />
                    <Route path="/set" element={< QuizSetPage />} />
                    <Route path="/list" element={<QuizListPage />} />
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
