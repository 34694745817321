// Типы действий
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

// Действия
export const login = () => {
    return { type: LOGIN };
};

export const logout = () => {
    return { type: LOGOUT };
};
