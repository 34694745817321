import React, {useState, useEffect} from 'react';
import Pagination from '@mui/material/Pagination';
import QuizInfoCard from "../../../components/QuizInfoCard/QuizInfoCard";
import quizListService from "../../../client/quizClient";

const QuizListPage = () => {
    const [quizzes, setQuizzes] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [showCorrectAnswers, setShowCorrectAnswers] = useState({});

    useEffect(() => {
        quizListService.fetchQuizzes(page, size)
            .then(data => {
                if (data && Array.isArray(data.content)) {
                    setQuizzes(data.content);
                    setTotalPages(data.totalPages);
                } else {
                    console.error('Invalid data format for quizzes:', data);
                }
            })
            .catch(error => console.error('Error fetching quizzes', error));
    }, [page, size]);

    const handleCardClick = (index) => {
        setShowCorrectAnswers(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const handleChangePage = (event, value) => {
        setPage(value); // Set the new page
        setShowCorrectAnswers({}); // Reset the showCorrectAnswers state
        // Scroll to the top of the page
    };



    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {quizzes.map((quiz, index) => (
                <QuizInfoCard
                    key={quiz.id}
                    quiz={quiz}
                    index={index}
                    showCorrectAnswers={showCorrectAnswers}
                    handleCardClick={handleCardClick}
                />
            ))}

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Pagination
                    variant="outlined"
                    color="primary"
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                />
            </div>
        </div>
    );
};

export default QuizListPage;
