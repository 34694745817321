import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from "../../redux/actions/drawerActions";
import UserProfile from "../UserProfile/UserProfile";
import { toggleUserProfile } from '../../redux/actions/profileActions'; // Импорт нового экшена

export default function Topbar() {
    const dispatch = useDispatch();
    const isProfileOpen = useSelector(state => state.profile.isProfileOpen); // Получение состояния из Redux

    // Функция для переключения состояния боковой панели
    const handleToggleDrawer = () => {
        dispatch(toggleDrawer());
    };

    // Функция для переключения видимости профиля
    const handleToggleProfile = () => {
        dispatch(toggleUserProfile());
    };

    // Inline стили для элементов компонента
    const styles = {
        topbar: {
            width: '100%',
            height: '50px',
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 999,
            borderBottom: '2px solid #e0e0e0',
        },
        topbarWrapper: {
            height: '100%',
            padding: '0 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        logo: {
            fontWeight: 'bold',
            fontSize: '30px',
            color: '#04601e',
        },
        topRight: {
            display: 'flex',
            alignItems: 'center',
        },
        topAvatar: {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            cursor: 'pointer',
        }
    };

    return (
        <div style={styles.topbar}>
            <div style={styles.topbarWrapper}>
                <div className="topleft">
                    <MenuIcon style={{ marginRight: '10px' }} onClick={handleToggleDrawer} />
                    <span style={styles.logo}>QUIZ</span>
                </div>
                <div style={styles.topRight}>
                    <img
                        src="https://g1tech.org/login-logo.png"
                        style={styles.topAvatar}
                        alt="User Avatar"
                        onClick={handleToggleProfile} // Обработчик нажатия на аватар
                    />
                    {isProfileOpen && <UserProfile />} {/* Компонент профиля, который отображается по условию */}
                </div>
            </div>
        </div>
    );
}
