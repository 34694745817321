import React, { useState } from 'react';
import './Drawer.css';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SchoolIcon from '@mui/icons-material/School';
import { toggleDrawer } from "../../redux/actions/drawerActions";

const pages = [
    {label: 'loop', icon: <AllInclusiveIcon/>},
    {label: 'learn', icon: <SchoolIcon/>},
    {label: 'add', icon: <AddCircleIcon/>},
    {label: 'stat', icon: <QueryStatsIcon/>},
    {label: 'set', icon: <SettingsSuggestIcon/>},
    {label: 'list', icon: <FormatListBulletedIcon/>},
];

export default function Drawer() {
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (index) => {
        setSelectedIndex(index);

        if (window.innerWidth <= 768) {
            dispatch(toggleDrawer());
        }
    };

    return (
        <div className="drawer">
            <div className="drawerWrapper">
                <div className="drawerMenu">
                    <List>
                        {pages.map((page, index) => (
                            <ListItemButton
                                key={page.label}
                                selected={location.pathname === `/${page.label}`}
                                onClick={() => handleListItemClick(index)}
                                component={Link}
                                to={`/${page.label}`}
                            >
                                <ListItemIcon>{page.icon}</ListItemIcon>
                                <ListItemText primary={page.label}/>
                            </ListItemButton>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    );
}
