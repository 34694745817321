import React, {useEffect, useRef} from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';
import { toggleUserProfile } from '../../redux/actions/profileActions'; // Импорт нового экшена

const styles = {
    profileDropdown: {
        position: 'absolute',
        right: '20px',
        top: '60px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '16px',
        padding: '10px',
    },
    userInfo: {
        paddingBottom: '10px',
        padding: '10px',
        marginBottom: '10px',
        borderBottom: '2px dashed #e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    username: {
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '5px',
    },
    email: {
        color: '#555',
        fontSize: '0.9rem',
    },
    listItem: {
        padding: '2px 10px',
    },
    listItemIcon: {
        minWidth: '40px',
    },
};

function UserProfile() {
    const dispatch = useDispatch();
    const ref = useRef(null); // Создаем ref для компонента UserProfile

    // Функция для обработки клика вне компонента
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            dispatch(toggleUserProfile()); // Закрыть UserProfile, если клик вне компонента
        }
    };

    // Добавление и удаление обработчика событий
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleActionClick = (action) => {
        if (action === 'logout') {
            dispatch(logout());
        }
        dispatch(toggleUserProfile());
    };

    return (
        <div ref={ref} style={styles.profileDropdown}> {/* Установка ref для компонента */}
            <div style={styles.userInfo}>
                <div style={styles.username}>Nikolay</div>
                <div style={styles.email}>nick@g1cloud.cc</div>
            </div>
            <List component="nav" aria-label="User settings">
                <ListItemButton style={styles.listItem} onClick={() => handleActionClick('profile')}>
                    <ListItemText primary="Profile" />
                </ListItemButton>
                <ListItemButton style={styles.listItem} onClick={() => handleActionClick('settings')}>
                    <ListItemText primary="Settings" />
                </ListItemButton>
                <ListItemButton style={styles.listItem} onClick={() => handleActionClick('logout')}>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </div>
    );
}

export default UserProfile;