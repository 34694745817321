// src/utils/fetchUtils.js
import {logout} from '../redux/actions/authActions';
import store from "../redux/store"; // Импорт действия logout


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthHeader = () => {
    const auth = localStorage.getItem('auth');
    return auth ? {'Authorization': 'Basic ' + auth} : {};
}

const customFetch = async (url, options = {}) => {
    const fullUrl = `${API_BASE_URL}${url}`;
    const headers = {
        'Content-Type': 'application/json',
        ...getAuthHeader(),
        ...options.headers,
    };
    const finalOptions = {...options, headers};
    try {
        const response = await fetch(fullUrl, finalOptions);
        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(logout());
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (response.headers.get("content-length") === "0" || response.status === 204) {
            return null;
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export default customFetch;
