import React, { useState, useEffect, useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import uPlot from 'uplot';
import 'uplot/dist/uPlot.min.css';
import QuizClient from "../../../client/quizClient";
import Grid from "@mui/material/Grid";

export default function QuizStatPage() {
    const [quizStats, setQuizStats] = useState([]);
    const [quizAggregateInfo, setQuizAggregateInfo] = useState([]);
    const uplotRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await QuizClient.fetchAggregateCorrectAnswersCount();
                setQuizStats(stats);
                const aggregateInfo = await QuizClient.fetchAggregateInfo();
                setQuizAggregateInfo(aggregateInfo);
                if (uplotRef.current) {
                    initUplot(aggregateInfo);
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных:', error);
            }
        };

        fetchData();
    }, []);






    const initUplot = (data) => {
        const xValues = data.map(item => item.rowIndex);
        const correctCounts = data.map(item => item.correctAnswersCount);
        const incorrectCounts = data.map(item => -item.incorrectAnswersCount);

        const opts = {
            width: uplotRef.current.offsetWidth,
            height: 300,

            legend: {show: false},


            scales: {
                x: {
                    time: false,
                },
                y: {
                    auto: false,
                    range: [Math.min(...incorrectCounts), Math.max(...correctCounts)], // диапазон ваших значений
                },
            },
            series: [
                {},
                {
                    paths: uPlot.paths.bars(),
                    label: "Correct",
                    width: 2,
                    stroke: "rgba(138, 213, 151)",

                    fill: "rgba(138, 213, 151)",
                },
                {
                    paths: uPlot.paths.bars(),
                    label: "Incorrect",
                    width: 2,
                    fill: "rgba(215, 116, 129)",

                },
            ],
            axes: [
                {
                    ticks: { show: true },
                    grid: { show: true },
                },
                {
                    ticks: { show: true },
                    grid: { show: true },
                }
            ],
        };


        new uPlot(opts, [xValues, correctCounts, incorrectCounts], uplotRef.current);
        };




    return (
        <Grid container spacing={2} direction="column">
            <Grid item xs={12} md={6}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Correct Answers Count</TableCell>
                                <TableCell align="right">Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quizStats.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.correctAnswersCount}
                                    </TableCell>
                                    <TableCell align="right">{row.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper>
                    <div ref={uplotRef} style={{ height: '300px', width: '100%' }} />
                </Paper>
            </Grid>
        </Grid>
    );





}