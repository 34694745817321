import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import quizSetService from "../../../client/quizSetClient";
import { useMediaQuery } from '@mui/material';


const QuizSetPage = () => {
    const [poolDTO, setPoolDTO] = useState({
        startQuizNumber: 0,
        finishQuizNumber: 290,
        quizSize: 290,
        poolSize: 10,
        correctAnswerLevel: 5
    });

    useEffect(() => {
        const fetchPoolDetails = async () => {
            try {
                const data = await quizSetService.fetchPoolDetails();
                setPoolDTO(prevState => ({
                    ...prevState,
                    startQuizNumber: data.startQuizNumber,
                    finishQuizNumber: data.finishQuizNumber,
                    quizSize: data.quizSize,
                    poolSize: data.poolSize,
                    correctAnswerLevel: data.correctAnswerLevel
                }));
            } catch (error) {
                console.error('Failed to fetch pool details:', error);
            }
        };

        fetchPoolDetails();
    }, []);

    const handleRangeSliderChange = (event, newValue) => {
        setPoolDTO(prevState => ({
            ...prevState,
            startQuizNumber: newValue[0],
            finishQuizNumber: newValue[1]
        }));
    };

    const handleSliderChange = (event, newValue, sliderName) => {
        setPoolDTO(prevState => ({
            ...prevState,
            [sliderName]: newValue
        }));
    };

    const handleChooseClick = async () => {
        try {
            await quizSetService.generatePool(poolDTO);
            // Handle your successful response here
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const isMobile = useMediaQuery('(max-width:600px)'); // Определение, является ли устройство мобильным


    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: '20px',
            gap: isMobile ? '30px' : '10px'
        }}>


            {/* Real Card */}
            <Card sx={{
                minWidth: '250px',
                maxWidth: '350px',
                margin: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #F0F8FFFF'
            }}>
                <CardContent>
                    <Typography variant="h5" component="div" style={{marginBottom: '10px'}}>
                        Real
                    </Typography>
                    <Typography variant="body2">
                        65 Questions in 2 hours: a simulation close to a real exam.
                    </Typography>
                </CardContent>
                <CardActions style={{marginTop: 'auto'}}>
                    <Button size="small" variant="outlined" color="primary" sx={{width: '100%'}}>Let's
                        practice!</Button>
                </CardActions>
            </Card>

            {/* Learn Card */}
            <Card sx={{
                minWidth: '250px',
                maxWidth: '350px',
                margin: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #F0F8FFFF'
            }}>
                <CardContent>
                    <Typography variant="h5" component="div" style={{marginBottom: '10px'}}>
                        Learn
                    </Typography>
                    <Typography variant="body2">
                        Specify the range of questions for practice: questions will be sequentially presented to you for
                        learning.
                    </Typography>
                </CardContent>
                <CardActions style={{marginTop: 'auto'}}>
                    <Button size="small" variant="outlined" color="primary" sx={{width: '100%'}}>Choose</Button>
                </CardActions>
            </Card>

            {/* Practice Card */}
            <Card sx={{
                minWidth: '250px',
                maxWidth: '350px',
              //  width: '25%',
                margin: '0 10px',
                display: 'flex',
                flexDirection: 'column',
                border: '1px solid #F0F8FFFF'
            }}>
                <CardContent>
                    <Typography variant="h5" component="div" style={{marginBottom: '10px'}}>
                        Practice
                    </Typography>
                    <Typography variant="body2">
                        You are given 10 questions after 5 correct answers; the question is marked as learned, and a new
                        one takes its place.
                    </Typography>
                    <br/>
                    <Typography variant="body2">Pool Size</Typography>
                    <Slider
                        value={poolDTO.poolSize}
                        onChange={(event, newValue) => handleSliderChange(event, newValue, 'poolSize')}
                        aria-labelledby="pool-size-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        min={0}
                        max={25}
                    />

                    <Typography variant="body2">Correct Answer Level</Typography>
                    <Slider
                        value={poolDTO.correctAnswerLevel}
                        onChange={(event, newValue) => handleSliderChange(event, newValue, 'correctAnswerLevel')}
                        aria-labelledby="correct-answer-level-slider"
                        valueLabelDisplay="auto"
                        step={1}
                        min={0}
                        max={15}
                    />
                    <Typography variant="body2">Quiz Range</Typography>
                    <Slider
                        getAriaLabel={() => 'Quiz range'}
                        value={[poolDTO.startQuizNumber, poolDTO.finishQuizNumber]}
                        onChange={handleRangeSliderChange}
                        valueLabelDisplay="auto"
                        min={1}
                        max={poolDTO.quizSize}
                       // marks
                    />
                </CardContent>
                <CardActions style={{marginTop: 'auto'}}>
                    <Button size="small" variant="outlined" color="primary" sx={{width: '100%'}}
                            onClick={handleChooseClick}>Choose</Button>
                </CardActions>
            </Card>
        </div>
    );
}

export default QuizSetPage;
