import customFetch from "../utils/fetchUtils";

const quizSetClient = {
    fetchPoolDetails: async () => {
        return await customFetch(`/pool/details`, {});
    },

    generatePool: async (poolDTO) => {
        return await customFetch(`/pool/generate`, {
            method: 'POST',
            body: JSON.stringify(poolDTO)
        });
    }
};

export default quizSetClient;