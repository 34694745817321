// src/redux/actions/drawerActions.js

// Action Types
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

// Action Creators
export const toggleDrawer = () => {
    return {
        type: TOGGLE_DRAWER
    };
};
