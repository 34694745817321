// QuizLearnPage.jsx
import React, { useState, useEffect } from 'react';
import QuizCard from "../../../components/QuizCard/QuizCard";
import quizService from "../../../client/quizClient";

export default function QuizLearnPage() {
    const [quiz, setQuiz] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const [checked, setChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Initial loading state
    const [error, setError] = useState(null);
    const [isMultipleCorrect, setIsMultipleCorrect] = useState(false);

    const fetchQuiz = async () => {
        setIsLoading(true);
        try {
            const data = await quizService.fetchQuizForLearning(); // предполагаемая функция в quizService
            setQuiz(data);
            setIsMultipleCorrect(data.answers.filter(answer => answer.isCorrect).length > 1);
            setIsLoading(false);
            setChecked(false);
            setSelectedOptions(new Set());
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchQuiz();
    }, []);

    const handleOptionChange = (event) => {
        if (isMultipleCorrect) {
            const newSelectedOptions = new Set(selectedOptions);
            if (newSelectedOptions.has(event.target.value)) {
                newSelectedOptions.delete(event.target.value);
            } else {
                newSelectedOptions.add(event.target.value);
            }
            setSelectedOptions(newSelectedOptions);
        } else {
            setSelectedOptions(new Set([event.target.value]));
        }
    };

    const handleCheckClick = async () => {
        setChecked(true);

        if (quiz && quiz.id) {
            try {
                const isCorrect = isMultipleCorrect
                    ? Array.from(selectedOptions).every(option => isAnswerCorrect(option))
                    : isAnswerCorrect([...selectedOptions][0] || '');

                await quizService.submitQuizAnswer(quiz.id, isCorrect); // предполагаемая функция в quizService

                // Обработка успешного ответа, если необходимо
            } catch (error) {
                console.error("There was an error submitting the answer: ", error);
            }
        }
    };


    const handleNextClick = () => {
        fetchQuiz();
    };

    const isAnswerCorrect = (answerText) => {
        return quiz.answers.some(answer => answer.text === answerText && answer.isCorrect);
    };

    if (isLoading) return <div>Loading...</div>; // Replace with a loading spinner or animation
    if (error) return <p>Error loading quiz: {error}</p>;

    return quiz ? (
        <QuizCard
            quiz={quiz}
            isMultipleCorrect={isMultipleCorrect}
            selectedOptions={selectedOptions}
            handleOptionChange={handleOptionChange}
            checked={checked}
            handleCheckClick={handleCheckClick}
            handleNextClick={handleNextClick}
            isAnswerCorrect={isAnswerCorrect}
        />
    ) : (
        <div>No quiz data available.</div> // Placeholder message or component
    );
}
