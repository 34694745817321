import { combineReducers } from 'redux';
import drawerReducer from './drawerReducer';
import authReducer from './authReducer';
import profileReducer from "./profileReducer"; // Импорт нового редуктора

const rootReducer = combineReducers({
    drawer: drawerReducer,
    auth: authReducer,
    profile: profileReducer

});

export default rootReducer;