import React, { useState } from 'react';
import {
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Paper,
    Typography,
    Box
} from '@mui/material';
import quizSetService from "../../client/quizSetClient";
import { useDispatch } from 'react-redux'; // Импортируем useDispatch
import { login } from '../../redux/actions/authActions';

import { useNavigate } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [keepSignedIn, setKeepSignedIn] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();




        const credentials = `${encodeURIComponent(email)}:${encodeURIComponent(password)}`;
        const base64EncodedCredentials = btoa(unescape(encodeURIComponent(credentials)));

        localStorage.setItem('auth', base64EncodedCredentials);


        quizSetService.fetchPoolDetails()
            .then(() => {
                dispatch(login());
                navigate('/');
            })
            .catch((error) => {
                console.error('Login failed:', error);
                setPassword('');
                toast.error("Incorrect Login");
                // Обработка ошибки
            });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            paddingTop="10vh" // 20% от верха страницы
        >
            <ToastContainer />
            <Paper elevation={3} style={{ padding: '2rem', width: '80%', maxWidth: '400px' }}>
                <Typography variant="h4" gutterBottom>
                    Login
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Email Address"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={keepSignedIn}
                                onChange={(e) => setKeepSignedIn(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Keep me signed in"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ marginTop: '1rem' }}
                    >
                        Login
                    </Button>
                    <Box display="flex" justifyContent="space-between" marginTop="1rem">
                        <Link href="#" variant="body2">
                            Don't have an account?
                        </Link>
                        <Link href="#" variant="body2">
                            Forgot Password?
                        </Link>
                    </Box>
                </form>
            </Paper>
        </Box>
    );
};

export default LoginPage;
