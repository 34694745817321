// reducers/profileReducer.js

import { TOGGLE_USER_PROFILE } from '../actions/profileActions';

const initialState = {
    isProfileOpen: false
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_USER_PROFILE:
            return {
                ...state,
                isProfileOpen: !state.isProfileOpen
            };
        default:
            return state;
    }
};

export default profileReducer;
