import customFetch from "../utils/fetchUtils";


const QuizClient = {
    addQuiz: async (question, answers, answersNotDefinitive) => {
        return await customFetch(`/quiz`, {
            method: 'POST',
            body: JSON.stringify({
                question, answersNotDefinitive, answers,
            }),
        });

    },

    fetchQuizForLearning: async () => {
        return await customFetch(`/quiz/learn`, {});
    },

    submitQuizAnswer: async (quizId, isCorrect) => {
        return await customFetch(`/quiz/${quizId}/answer`, {
            method: 'POST',
            body: JSON.stringify({isCorrect}),
        });
    },

    fetchQuizzes: async (page, size) => {
        return  await customFetch(`/quizzes?page=${page - 1}&size=${size}`, {});
    },

    fetchQuizForLoop: async () => {
        return  await customFetch(`/quiz/loop`, {});
    },

    fetchAggregateCorrectAnswersCount: async () => {
        return await customFetch(`/quizzes/aggregate/correctAnswersCount`, {});
    },

    fetchAggregateInfo: async () => {
        return await customFetch(`/quizzes/aggregate/info`, {});
    },


};

export default QuizClient;
