// src/redux/reducers/drawerReducer.js

import { TOGGLE_DRAWER } from '../actions/drawerActions';

const initialState = {
    isOpen: true // или false, в зависимости от вашего начального состояния
};

const drawerReducer = (state = initialState, action) => {

    switch (action.type) {
        case TOGGLE_DRAWER:

            return {
                ...state,
                isOpen: !state.isOpen
            };
        default:
            return state;
    }
};

export default drawerReducer;
