// src/redux/store.js

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers'; // Убедитесь, что у вас есть корневой редуктор
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const initialState = {};
const middleware = [thunk];

// Настройки для redux-persist
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'] // Здесь укажите редьюсеры, которые нужно сохранять. Например, 'auth'.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer, // Используйте persistedReducer вместо rootReducer
    initialState,
    compose(
        applyMiddleware(...middleware),
        // Убедитесь, что инструменты разработчика Redux не мешают работе redux-persist
        typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ?
            window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

export const persistor = persistStore(store);
export default store;
