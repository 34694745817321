import React, {useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import StartIcon from '@mui/icons-material/Start';


function QuizCard({
                      quiz,
                      isMultipleCorrect,
                      selectedOptions,
                      handleOptionChange,
                      checked,
                      handleCheckClick,
                      handleNextClick,
                      isAnswerCorrect // Предполагая, что эта функция передается как проп
                  }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Новое состояние для отслеживания режима кнопки
    const [buttonMode, setButtonMode] = useState('check');

    // Объединенный обработчик для кнопки
    const handleButtonClick = () => {
        if (buttonMode === 'check') {
            handleCheckClick();
            setButtonMode('next');
        } else {
            handleNextClick();
            setButtonMode('check');
        }
    };

    const getFormControlLabelStyle = (answer) => ({
        margin: isMobile ? '2px 0' : '10px 0',
        border: isMobile
            ? (checked ? (isAnswerCorrect(answer.text) ? '2px solid green' : (selectedOptions.has(answer.text) ? '2px solid #b22222' : '2px solid transparent')) : (selectedOptions.has(answer.text) ? '2px solid grey' : '2px solid transparent'))
            : (checked && isAnswerCorrect(answer.text) ? '2px solid green' : '2px solid transparent'),
        borderRadius: '4px',
        padding: '5px',
        maxWidth: '100%',
    });

    return (
        <div style={{display: 'flex', justifyContent: 'center', padding: isMobile ? '0' : '20px'}}>
            <Card>
                <CardContent>
                    <Typography variant={isMobile ? "body1" : "h5"}
                                style={{...(isMobile ? {fontSize: '110%', marginBottom: '20px'} : {})}}>
                        {quiz.question}
                    </Typography>
                    <FormControl component="fieldset" fullWidth margin="normal">
                        {isMultipleCorrect ? (
                            quiz.answers.map((answer, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={<Checkbox
                                        checked={selectedOptions.has(answer.text)}
                                        onChange={handleOptionChange}
                                        style={{display: isMobile ? 'none' : 'inline-flex'}} // Скрывает Checkbox на мобильных устройствах
                                        value={answer.text}/>}
                                    label={
                                        <Typography style={{
                                            wordWrap: 'break-word',
                                            maxWidth: '100%', // Убедитесь, что у Typography есть ширина, чтобы wordWrap мог сработать.
                                            hyphens: 'auto' // Это позволит браузеру автоматически переносить слова.
                                        }}>
                                            {answer.text}
                                        </Typography>
                                    }
                                    style={getFormControlLabelStyle(answer)}
                                />
                            ))
                        ) : (
                            <RadioGroup
                                aria-label="quiz-options"
                                name="quiz-options"
                                value={[...selectedOptions][0] || ''}
                                onChange={handleOptionChange}
                            >
                                {quiz.answers.map((answer, index) => (
                                    <FormControlLabel
                                        key={index}
                                        value={answer.text}
                                        control={<Radio
                                            style={{display: isMobile ? 'none' : 'inline-flex'}}/>} // Скрывает Radio на мобильных устройствах
                                        label={
                                            <Typography style={{
                                                wordWrap: 'break-word',
                                                maxWidth: '100%', // Убедитесь, что у Typography есть ширина, чтобы wordWrap мог сработать.
                                                hyphens: 'auto' // Это позволит браузеру автоматически переносить слова.
                                            }}>
                                                {answer.text}
                                            </Typography>
                                        }
                                        style={getFormControlLabelStyle(answer)}
                                    />
                                ))}
                            </RadioGroup>
                        )}
                    </FormControl>
                    <div style={{
                        display: 'flex',
                        justifyContent: isMobile ? 'center' : 'flex-end', // центрируем на мобильных, прижимаем к правому краю на десктопах
                        marginTop: '20px'
                    }}>
                        <Button
                            variant="outlined"



                            startIcon={buttonMode === 'check' ? <CheckIcon /> : null}
                            endIcon={buttonMode === 'next' ? <StartIcon /> : null}

                            onClick={handleButtonClick}
                            style={{
                                // backgroundColor: '#f2f4fa',
                                width: isMobile ? '100%' : '200px' // 100% ширины на мобильных, 200px на десктопах
                            }}
                        >
                            {buttonMode === 'check' ? 'Check' : 'Next'}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default QuizCard;
