import React from 'react';
import './QuizInfoCard.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";


const QuizInfoCard = ({ quiz, index, showCorrectAnswers, handleCardClick }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <Card
            key={quiz.id}
            className="quizInfoCard"
            onClick={() => handleCardClick(index)}
        >
            <CardContent style={{ background: '#CEECFDFF', padding: '10px' }}>
                <Typography color="text.secondary" style={{ fontSize: '80%' }}>
                    ID: {quiz.id}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography
                    variant={isMobile ? "body1" : "h6"}
                    style={isMobile ? { fontSize: '110%' } : {}}
                    component="div">
                    {quiz.question}
                </Typography>
                <Typography variant="body1" color="text.secondary" component="div">
                    <List>
                        {quiz.answers.map((answer, answerIndex) => (
                            <ListItem
                                key={answerIndex}
                                style={{
                                    border: showCorrectAnswers[index] && answer.isCorrect
                                        ? '1px solid green'
                                        : '1px solid transparent',
                                    borderRadius: '5px',
                                    padding: '5px',
                                    margin: '5px 0',
                                }}
                            >
                                {answer.text}
                            </ListItem>
                        ))}
                    </List>
                </Typography>
                <Typography color="text.secondary" style={{ fontSize: '70%' }}>
                    Correct: {quiz.correctAnswersCount} / Incorrect: {quiz.incorrectAnswersCount}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default QuizInfoCard;
